import { reactive } from 'vue'
import { useForm } from '../../Base/useForm'
import { loginValid, passwordValid } from '@/utils/validator/fields'
import { FormHandler } from '@/utils/use/formHandler'
import { Alert } from '@/extension/Alert/Alert'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { APP_MODAL } from '@/utils/consts/appModal'

export function useLoginForm () {
  const store = useStore()
  const router = useRouter()

  const formData = reactive({
    password: '',
    login: ''
  })

  const rules = {
    password: passwordValid,
    login: loginValid
  }

  const formAction = async () => {
    try {
      await store.dispatch('auth/login', formData)

      if (store.getters['redirect/getRoute']) {
        await router.push(store.getters['redirect/getRoute'])
      } else {
        await router.push({ name: 'showcase' })
      }

      // аккредитован пользователь или нет
      if (store.getters['auth/isUser']) {
        store.commit('modal/OPEN_MODAL', APP_MODAL.needAccreditation)
      }
    } catch (e) {
      await Alert.show('error', 'Не удалось пройти авторизацию')
    }
  }

  const form = FormHandler(formAction)

  return {
    ...useForm(rules, formData, form.handlerErrors),
    serverErrors: form.serverErrors
  }
}
