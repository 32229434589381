import { reactive } from 'vue'
import { useForm } from '../../Base/useForm'
import { loginValid } from '@/utils/validator/fields'
import { FormHandler } from '@/utils/use/formHandler'
import { reset } from '@/http/auth/authApi'
import { Alert } from '@/extension/Alert/Alert'

export function useResetForm () {
  const formData = reactive({
    login: ''
  })

  const rules = {
    login: loginValid
  }

  const formAction = async () => {
    const data = await reset(formData)
    if (!data) {
      await Alert.show('error', 'Пользователь с таким логином не существует.')
      return
    }
    await Alert.show('success', data.message)
    formData.login = ''
  }

  const form = FormHandler(formAction)

  return {
    ...useForm(rules, formData, form.handlerErrors),
    serverErrors: form.serverErrors
  }
}
